import {doc, setDoc} from "firebase/firestore";
import {httpsCallable} from 'firebase/functions';
import {auth, firestore, functions} from "../firebase.ts";


export const updateLinkTitle = (shortCode: string, title: string) => {
    const docRef = doc(firestore, 'shortenedURLs', shortCode);
    return setDoc(docRef, {
        title
    }, {merge: true});
}


export async function updateLinkMetadata(shortCode: string) {
    try {
        // Ensure the user is authenticated
        const user = auth.currentUser;
        if (!user) {
            throw new Error('User must be authenticated');
        }

        // Call the updateMetadata function
        const updateMetadata = httpsCallable(functions, 'urlshortener_updateMetadata');
        const result = await updateMetadata({shortCode});

        console.debug((result.data as any)?.message); // Metadata updated successfully

    } catch (error) {
        console.error('Error updating metadata:', error);
    }
}
