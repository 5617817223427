// src/firebase.ts
import { initializeApp, FirebaseOptions } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import {getFunctions} from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyA5-RGx3uBU1xW13ROJzsCyO0CGNjYNF4Q",
    authDomain: "lasoxp-prod.firebaseapp.com",
    databaseURL: "https://lasoxp-prod-default-rtdb.firebaseio.com",
    projectId: "lasoxp-prod",
    storageBucket: "lasoxp-prod.appspot.com",
    messagingSenderId: "839147903975",
    appId: "1:839147903975:web:7f2777e68c486949263b42",
    measurementId: "G-BTHV76122J"
} as FirebaseOptions;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Firestore
export const auth = getAuth(app);
auth.tenantId = 'laso-to-m322e';
export const firestore = getFirestore(app, 'url-shortener');

export const functions = getFunctions(app);

if (import.meta.env.DEV) {
    (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LdCNWkqAAAAAJVdt9e0mClx0iqwQdKGdE0idy4x'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
});


