const Reports = () => {
    return (
        <div className="container">
            <h1>Link Reports</h1>

            <div className="link-grid">
                <div className="report-card">
                    <p><strong>Visit:</strong> 20/09/2024</p>
                    <p>Referrer: Google</p>
                    <p>Device: Mobile</p>
                </div>
                <div className="report-card">
                    <p><strong>Visit:</strong> 21/09/2024</p>
                    <p>Referrer: Facebook</p>
                    <p>Device: Desktop</p>
                </div>
            </div>

            <button style={{ marginTop: '20px' }}>Go Back</button>
        </div>
    );
};

export default Reports;
