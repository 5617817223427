import openlink from '../assets/external-link.svg';

const OpenLinkButton = ({link, newTab = true}:{link: string, newTab?:boolean}) => {

    return <a className={'icon-button'} href={link} target={newTab ? '_blank' : '_self'}>
        <img src={openlink} alt={'Open link'} />
    </a>
}

export default OpenLinkButton;
