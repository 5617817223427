import {useEffect, useState} from 'react';
import {doc, onSnapshot} from 'firebase/firestore';
import {firestore} from '../firebase';
import {CopyToClipbaordButton} from "./CopyToClipboard.tsx";
import {updateLinkMetadata, updateLinkTitle} from "./GeneralInfo.data.ts";
import editIcon from '../assets/edit.svg';
import OpenLinkButton from "./OpenLinkButton.tsx";
import {ShortenedURLData} from "../types/ShortenedURL.ts";
import LinkPreviewCard from "./LinkPreviewCard.tsx";
import './GeneralInfo.styles.css';


interface GeneralInfoProps {
    shortCode: string;
}


const GeneralInfo = ({shortCode}: GeneralInfoProps) => {
    const [generalInfo, setGeneralInfo] = useState<ShortenedURLData | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setLoading(true);

        return onSnapshot(doc(firestore, 'shortenedURLs', shortCode), (docSnap) => {
            if (docSnap.exists()) {
                setGeneralInfo(docSnap.data() as ShortenedURLData);
            } else {
                setError('No data found for this shortened URL.');
            }
            setLoading(false);
        }, (err) => {
            setError(`Failed to fetch general information. ${err.message}`);
        });
    }, [shortCode]);

    if (loading) return <p>Loading general info...</p>;
    if (error) return <p style={{color: 'red'}}>{error}</p>;

    return (
        generalInfo && (
            <div className="general-info-container">
                <div className="info-left">
                    <h3>General Information</h3>
                    <LinkTitle title={generalInfo.title} shortCode={generalInfo.shortCode}/>
                    <div className={'inline-flex'}>
                        <p className={'ellipsis'}><strong>Original URL:</strong> {generalInfo.originalURL}</p>
                        <OpenLinkButton link={generalInfo.originalURL}/>
                        </div>
                    <div className="inline-flex"><p><strong>Shortened URL:</strong> /{generalInfo.shortCode} </p>
                        <CopyToClipbaordButton text={`${window.location.origin}/${generalInfo.shortCode}`}/></div>
                    <p><strong>Visits:</strong> {generalInfo.visits}</p>
                    <p><strong>Created At:</strong> {new Date(generalInfo.createdAt.seconds * 1000).toLocaleString()}
                    </p>
                </div>
                <div className="info-right">
                    {generalInfo.metadata ? <LinkPreviewCard metadata={generalInfo.metadata} shortUrl={shortCode}/> : (
                        <button onClick={() => updateLinkMetadata(shortCode)}>Fetch Metadata</button>
                    )}
                </div>
            </div>
        )
    );
};

const LinkTitle = ({title, shortCode}: { title?: string, shortCode: string }) => {

    function showPrompt() {
        const newTitle = prompt('Please enter a title', title);

        if (newTitle) {
            updateLinkTitle(shortCode, newTitle).catch((err) => {
                alert(`Error ${err.message}`)
            });
        }
    }

    return !title ? <p>
            <button onClick={showPrompt}>Set title</button>
        </p>
        : <p className={'inline-flex'}><strong>Title:</strong> {title}
            <button className="icon-button" title={'Edit title'} onClick={showPrompt}><img src={editIcon}
                                                                                           alt={'edit icon'}/></button>
        </p>
}

export default GeneralInfo;
