import {useEffect, useState} from 'react';
import {auth, firestore} from '../firebase'; // Firebase Auth and Firestore
import {
    collection,
    DocumentData,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter,
    where
} from 'firebase/firestore';
import {signOut} from 'firebase/auth';
import {useNavigate} from 'react-router-dom'; // React Router for navigation
import useUserRole from './useUserRole';
import LinkItem from "./LinkItem.tsx";
import {useDebugMode} from "../utils/useDebugMode.ts"; // Custom hook to fetch user role

interface LinkData {
    id: string;
    originalURL: string;
    shortCode: string;
    createdAt: any; // Firestore timestamp
    userId?: string; // Optional userId
    visits?: number;
    title?: string;
}

const Dashboard = () => {
    const [links, setLinks] = useState<LinkData[]>([]);
    const isDebugMode = useDebugMode();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [lastVisible, setLastVisible] = useState<DocumentData | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const navigate = useNavigate();
    const { role, loading: roleLoading, error: _roleError } = useUserRole(); // Hook to fetch user role

    const pageSize = 10; // Number of documents per page

    // Fetch the user's links from Firestore
    useEffect(() => {
        setLoading(true);

        const shortenedURLsRef = collection(firestore, 'shortenedURLs');
        let q;

        if (isDebugMode && role === 'admin') {
            // Admins fetch all links in descending order
            q = query(shortenedURLsRef, orderBy('createdAt', 'desc'), limit(pageSize));
        } else {
            // Regular users fetch only their own links
            const userId = auth.currentUser?.uid;
            q = query(shortenedURLsRef, where('userId', '==', userId), orderBy('createdAt', 'desc'), limit(pageSize));
        }

        return onSnapshot(q, (querySnapshot) => {
            const linksData: LinkData[] = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            })) as LinkData[];

            setLinks(linksData);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]); // Track the last visible document

            if (querySnapshot.docs.length < pageSize) {
                setHasMore(false); // If less than pageSize items, no more pages
            }
            setLoading(false);
        },(err) => {
            console.error(`Error fetching links ${err.message}`);
            setLoading(false);
        })

    }, [role, roleLoading, isDebugMode]);

    // Handle fetching the next page
    const fetchNextPage = async () => {
        if (!lastVisible || !hasMore) return; // If there's no more data or already loaded everything

        setLoading(true);
        try {
            const shortenedURLsRef = collection(firestore, 'shortenedURLs');
            let q;

            if (role === 'admin') {
                // Admins fetch the next page of all links
                q = query(shortenedURLsRef, orderBy('createdAt', 'desc'), startAfter(lastVisible), limit(pageSize));
            } else {
                // Regular users fetch their own links
                const userId = auth.currentUser?.uid;
                q = query(shortenedURLsRef, where('userId', '==', userId), orderBy('createdAt', 'desc'), startAfter(lastVisible), limit(pageSize));
            }

            const querySnapshot = await getDocs(q);
            const newLinksData: LinkData[] = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            })) as LinkData[];

            setLinks((prevLinks) => [...prevLinks, ...newLinksData]); // Append new data to existing links
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]); // Update the last visible document

            if (querySnapshot.docs.length < pageSize) {
                setHasMore(false); // No more pages if fetched less than pageSize
            }
        } catch (err) {
            setError('Failed to fetch links');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    // Handle user logout
    const handleLogout = async () => {
        try {
            await signOut(auth); // Sign out the user
            navigate('/login');  // Redirect to login page after logout
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    return (
        <div className="container">
            <h1>Your Dashboard</h1>
            <button onClick={() => navigate('/d/create')} style={{marginTop: '20px'}}>
                Create New Short URL
            </button>

            {loading && <p>Loading...</p>}
            {error && <p style={{color: 'red'}}>{error}</p>}

            <div className="link-grid">
                {links.length > 0 ? (
                    links.map((link) => (
                        <LinkItem
                            key={link.id}
                            originalURL={link.originalURL}
                            shortCode={link.shortCode}
                            createdAt={link.createdAt}
                            visits={link.visits || 0}
                            title={link.title}// Pass visits count to LinkItem
                        />
                    ))
                ) : (
                    <p>No URLs found.</p>
                )}
            </div>

            {/* Pagination Button */}
            {hasMore && !loading && (
                <button onClick={fetchNextPage} style={{marginTop: '20px'}}>
                    Load More
                </button>
            )}

            {/* Logout button */}
            <button onClick={handleLogout} style={{marginTop: '20px', backgroundColor: '#f44336', color: '#fff'}}>
                Logout
            </button>
        </div>
    );
};

export default Dashboard;
