import OSChart from './OSChart';
import BrowserChart from './BrowserChart';
import CityChart from './CityChart';
import DailyVisitsPieChart from './DailyVisitsPieChart'; // Import the new component
import "chart.js/auto";


interface VisitsChartProps {
    dailyVisits: { [key: string]: number };
    osBreakdown: { [key: string]: number };
    browserBreakdown: { [key: string]: number };
    cityBreakdown: { [key: string]: number };
    countryBreakdown: { [key: string]: number };
}

const VisitsChart = ({dailyVisits, osBreakdown, browserBreakdown, cityBreakdown, countryBreakdown}: VisitsChartProps) => {
    return (
        <div className="chart-grid">
            <div>
                <h3>Visits by Day</h3>
                <DailyVisitsPieChart dailyVisits={dailyVisits}/>
            </div>

            <div>
                <h3>Operating System Breakdown</h3>
                <OSChart osBreakdown={osBreakdown}/>
            </div>

            <div>
                <h3>Browser Breakdown</h3>
                <BrowserChart browserBreakdown={browserBreakdown}/>
            </div>

            <div>
                <h3>City Breakdown (Top 20)</h3>
                <CityChart cityBreakdown={cityBreakdown}/>
            </div>

            <div>
                <h3>Country Breakdown (Top 20)</h3>
                <CityChart cityBreakdown={countryBreakdown}/>
            </div>
        </div>
    );
};

export default VisitsChart;
