import {useState} from 'react';
import {doc, getDoc, setDoc} from 'firebase/firestore';
import {FirebaseError} from 'firebase/app';
import {auth, firestore} from '../firebase'; // Firebase Auth and Firestore
import {useNavigate} from 'react-router-dom';
import {CopyToClipbaordButton} from "./CopyToClipboard.tsx";

const CreateShortURL = () => {
    const [newURL, setNewURL] = useState<string>(''); // State for new URL input
    const [title, setTitle] = useState<string>(''); // State for new URL input
    const [shortCode, setShortCode] = useState<string | null>(null); // State for generated short code
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    // Function to generate a short code
    const generateShortCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 6; i++) { // Generate 6 characters for the short code
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    // Check if the short code already exists in Firestore
    const checkShortCodeExists = async (shortCode: string) => {
        const docRef = doc(firestore, 'shortenedURLs', shortCode);
        try {
            const docSnap = await getDoc(docRef);
            return docSnap.exists();
        } catch (error) {
            if (error instanceof FirebaseError && error.code === 'permission-denied') {
                return false;
            } else throw error;
        }
    };

    // Recursive function to generate a unique short code
    const getUniqueShortCode = async (): Promise<string> => {
        const shortCode = generateShortCode();
        const exists = await checkShortCodeExists(shortCode);

        if (exists) {
            return getUniqueShortCode(); // Recursively generate a new code if it exists
        }

        return shortCode; // Return unique short code
    };

    // Validate and format URL using the URL object
    const formatURL = (url: string): string => {
        try {
            // Try to create a new URL object
            new URL(url);
            return url; // URL is valid, return as is
        } catch (error) {
            // If it fails, prepend 'https://'
            return `https://${url}`;
        }
    };

    // Handle URL creation
    const handleCreateShortURL = async () => {
        if (!newURL) {
            setError('Please enter a valid URL.');
            return;
        }

        // Format and validate the entered URL
        const formattedURL = formatURL(newURL);

        setLoading(true);
        const userId = auth.currentUser?.uid;

        try {
            // Generate a unique short code
            const shortCode = await getUniqueShortCode();

            // Save the URL in Firestore using the shortCode as the document ID
            const docRef = doc(firestore, 'shortenedURLs', shortCode);
            await setDoc(docRef, {
                originalURL: formattedURL,
                shortCode: shortCode,
                ...(title ? {title} : {}),
                userId: userId,
                createdAt: new Date(), // Add created date
            });

            // Update UI with the new short code
            setShortCode(shortCode);
            setNewURL(''); // Clear the input field
            setError(null); // Clear any previous errors
        } catch (err) {
            setError('Failed to create short URL');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <h3>Create New Short URL</h3>
            {error && <p style={{color: 'red'}}>{error}</p>}

            <input
                type="text"
                value={newURL}
                onChange={(e) => setNewURL(e.target.value)}
                placeholder="Enter original URL"
            />

            <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title"
            />
            <button onClick={handleCreateShortURL} disabled={loading}>
                {loading ? 'Creating...' : 'Create Short URL'}
            </button>

            {shortCode && (
                <p>
                    Short URL created: <a href={`/${shortCode}`} target={'_blank'}>/{shortCode}</a> <CopyToClipbaordButton
                    text={`${window.location.origin}/${shortCode}`}/>
                </p>
            )}

            <button onClick={() => navigate('/d/dashboard')} style={{marginTop: '20px'}}>
                Back to Dashboard
            </button>
        </div>
    );
};

export default CreateShortURL;
