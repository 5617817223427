import {Line} from 'react-chartjs-2';

interface DailyVisitsPieChartProps {
    dailyVisits: { [key: string]: number };
}

const DailyVisitsPieChart = ({ dailyVisits }: DailyVisitsPieChartProps) => {
    const dailyVisitsData = {
        labels: Object.keys(dailyVisits),
        datasets: [
            {
                label: 'Visits by Day',
                data: Object.values(dailyVisits),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
            },
        ],
    };

    return <Line data={dailyVisitsData} responsive={true} maintainAspectRatio={true} aspectRatio={1} />;
};

export default DailyVisitsPieChart;
