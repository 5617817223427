

export function copyToClipboard(contents: string, mimeType : 'text/html' | 'text/plain' | string = 'text/html') {
  if (!navigator.clipboard) {
    const listener = (e: ClipboardEvent) => {
      e.clipboardData?.setData(mimeType, contents);
    }

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

  } else {
    const blob = new Blob([contents], {type: mimeType});
    const data = [new ClipboardItem({[mimeType]: blob})];
    navigator.clipboard.write(data)
      .catch(
         (err) => {
           console.error('Could not copy the contents', err); // error
          alert('Could not copy the contents'); // error
        });
  }

}
