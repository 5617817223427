import { Pie } from 'react-chartjs-2';
import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

interface OSChartProps {
    osBreakdown: { [key: string]: number };
}

const OSChart = ({ osBreakdown }: OSChartProps) => {
    const chartRef = useRef<Chart<'pie'> | null>(null);

    // Cleanup logic to destroy chart instance on unmount
    useEffect(() => {
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, []);

    const chartData = {
        labels: Object.keys(osBreakdown),
        datasets: [
            {
                label: 'Operating System Breakdown',
                data: Object.values(osBreakdown),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return <Pie data={chartData} ref={chartRef}  responsive={true} maintainAspectRatio={true} aspectRatio={1} />;
};

export default OSChart;
