import './LinkPreviewCard.styles.css';
import {LinkMetadata} from "../types/ShortenedURL.ts";
import {updateLinkMetadata} from "./GeneralInfo.data.ts";

const LinkPreviewCard = ({metadata, shortUrl}: { metadata: LinkMetadata, shortUrl: string }) => {

    function onUpdateMetadata() {
        updateLinkMetadata(shortUrl);
    }

    if (!metadata) {
        return <button onClick={onUpdateMetadata}>Fetch Metadata</button>;
    }

    const {title, description, image} = metadata;

    return (
        <div>
            <div className="link-preview-card">
                {image && <img src={image} alt="Preview" className="preview-image"/>}
                <div className="preview-content">
                    <h4 className="preview-title">{title}</h4>
                    <p className="preview-description">{description}</p>
                </div>
            </div>
            <button onClick={onUpdateMetadata}>Refresh Metadata</button>
        </div>
    );
};

export default LinkPreviewCard;
