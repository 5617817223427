import {PropsWithChildren, useState} from 'react';
import {copyToClipboard} from '../utils/clipboard';
import copy_svg_icon from '../assets/copy-svgrepo-com.svg';
import thumbsUpSvgIcon from '../assets/thumbs-up-svgrepo-com.svg';

const CopyToClipboard = ({children, text, onCopy, format = 'text/plain'}: PropsWithChildren<{
    text: string;
    onCopy?(text: string, result: boolean): void;
    format?: 'text/html' | 'text/plain' | string
}>) => {

    return <div onClick={() => {
        copyToClipboard(text, format);
        onCopy?.(text, true);
    }}>
        {children}
    </div>;
};

export default CopyToClipboard


export const CopyToClipbaordButton = ({text}: { text: string }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000); // Revert back after 1 second
    };


    return <CopyToClipboard text={text} onCopy={handleCopy}>
        <div className="icon-button" title='Copy to clipboard'>
            <img src={isCopied ? thumbsUpSvgIcon : copy_svg_icon} alt="icon"/>
        </div>
    </CopyToClipboard>
}
