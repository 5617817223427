import {useEffect, useState} from "react";


export function useDebugMode() {
    const [keySequence, setKeySequence] = useState<string[]>([]);

    const [isDebugMode, setIsDebugMode] = useState<boolean>(false);

    useEffect(() => {
        const handleKeyDown = (event:KeyboardEvent) => {
            if (event.key === "d") {
                setKeySequence((prevSequence) => [...prevSequence, "d"].slice(-2));
            } else {
                // Reset sequence if any other key is pressed
                setKeySequence([]);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        // Check if the sequence is ["d", "d"]
        if (keySequence.join("") === "dd") {
            setIsDebugMode((prevValue) => (!prevValue))
            // Reset the sequence after detection to allow for further triggers
            setKeySequence([]);
        }

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [keySequence]);

    return isDebugMode;
}
